import { render, staticRenderFns } from "./distributeOwnSoftware.vue?vue&type=template&id=778a6819&scoped=true&"
import script from "./distributeOwnSoftware.vue?vue&type=script&lang=js&"
export * from "./distributeOwnSoftware.vue?vue&type=script&lang=js&"
import style0 from "./distributeOwnSoftware.vue?vue&type=style&index=0&id=778a6819&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778a6819",
  null
  
)

export default component.exports