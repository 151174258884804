<template>
  <div>
    <p><strong>New Device Lock Status</strong></p>
    <div class="field-group">
      <b-field
        label="Status"
        horizontal
        position="is-left"
        class="text-auto internal-field-group"
      >
        <b-select
          v-model="parameters.status"
          v-validate="'required|between:0,1'"
          placeholder="Lock Status"
          style="text-align:center;"
          name="lockStatus"
          expanded
          @input="onChangeParameters"
        >
          <option
            v-for="lockOption in lockOptions"
            :key="lockOption.value"
            :value="lockOption.value"
          >
            {{ lockOption.name }}
          </option>
        </b-select>
      </b-field>
      <span
        v-show="errors.has('lockStatus')"
        class="help is-danger"
      >{{ errors.first('lockStatus') }}</span>
    </div>
    <div
      v-if="parameters.status === deviceLockStatus.DISPLAY_MESSAGES_FREQUENTLY.value.dbValue"
      class="field-group"
    >
      <b-field
        label="Message"
        horizontal
        position="is-left"
        class="text-auto internal-field-group"
      >
        <b-field
          grouped
        >
          <b-input
            v-model="parameters.message"
            v-validate="'required|max:120'"
            placeholder="Message to display"
            expanded
            type="text"
            name="message"
            hide-details="auto"
            @input="onChangeParameters"
          />
        </b-field>
      </b-field>
      <span
        v-show="errors.has('message')"
        class="help is-danger"
      >{{ errors.first('message') }}</span>
    </div>
    <div
      v-if="parameters.status === deviceLockStatus.DISPLAY_MESSAGES_FREQUENTLY.value.dbValue"
      class="field-group"
    >
      <b-field
        label="Frequency"
        horizontal
        position="is-left"
        class="text-auto internal-field-group"
      >
        <b-field
          grouped
        >
          <b-input
            v-model="parameters.frequency"
            v-validate="'required|between:1,1440'"
            placeholder="Frequency in minutes"
            expanded
            type="number"
            name="frequency"
            hide-details="auto"
            @input="onChangeParameters"
          />
          <p
            class="control"
            style="margin: 6px 0px;"
          >
            Minutes
          </p>
        </b-field>
      </b-field>
      <span
        v-show="errors.has('frequency')"
        class="help is-danger"
      >{{ errors.first('frequency') }}</span>
    </div>
    <p
      v-if="parameters.status === deviceLockStatus.DISPLAY_MESSAGES_FREQUENTLY.value.dbValue
        && !errors.has('frequency')"
    >
      A notification will be shown each {{ parameters.frequency }}
      minute{{ parameters.frequency > 1 ? 's' : '' }} in windows devices.
    </p>
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

const { deviceLockStatus } = vtulEnums.enum.device;

export default {
  name: 'ChangeAMTWirelessStatus',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      deviceLockStatus,
      lockOptions: this.generateLockStatusOptions(deviceLockStatus),
      parameters: {
        status: undefined,
        message: '',
        frequency: 15,
      },
    };
  },
  mounted() {
    this.parent.$on('validateLockStatusInput', this.validateLockStatusInput);
  },
  methods: {
    onChangeParameters() {
      this.onUpdateParameters(this.parameters);
    },
    validateLockStatusInput(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
    generateLockStatusOptions(lockStatusEnum) {
      const options = [];

      Object.keys(lockStatusEnum).forEach((lockStatusKey) => {
        if (lockStatusEnum[lockStatusKey].value !== undefined
          && lockStatusEnum[lockStatusKey].value !== lockStatusEnum.LOCKED.value) {
          options.push({
            name: lockStatusEnum[lockStatusKey].value.name,
            value: lockStatusEnum[lockStatusKey].value.dbValue,
          });
        }
      });

      return options;
    },
  },
};
</script>

<style scoped>
.text-auto {
  text-align: initial !important;
}
.field-group {
  margin-bottom: 12px;
}
.internal-field-group {
  margin-bottom: 0px;
}
</style>
