<template>
  <div>
    <br>
    <p>
      Are you sure to execute {{ selectedAction.name }} on
      {{ selectedDevices.length === 1
        ? selectedDevices[0].name : 'selected devices' }}?
    </p>
    <div
      v-if="selectedDevices.length === 0 || (amtInfo && (amtInfo.length === 1
        && amtInfo[0].tlsLocal < 1 && amtInfo[0].tlsRemote < 1))"
    >
      <br>
      <div class="field">
        <b-checkbox
          v-model="changeSecurityCheck"
          @input="onChangeCheckBox"
        >
          Change TLS Security after remove Boardgent MPS
        </b-checkbox>
      </div>
      <div v-if="changeSecurityCheck">
        <p v-if="selectedDevices.length === 1">
          <span>Current TLS Security settings for {{ selectedDevices[0].name }} are:</span><br>
          <span>
            <strong>Local</strong> {{ tlsOptions.find(x => x.value === amtInfo[0].tlsLocal).name }}
          </span><br>
          <span>
            <strong>Remote</strong>{{ tlsOptions.find(x => x.value === amtInfo[0].tlsRemote).name }}
          </span>
        </p>
        <b-field label="">
          <b-select
            v-model="tlsOption"
            placeholder="Select the new TLS Security"
            required
            style="text-align:center;"
            @input="onChangeTLSOption"
          >
            <option
              v-for="amtTLS in tlsOptions"
              :key="amtTLS.value"
              :value="amtTLS.value"
            >
              {{ amtTLS.name }}
            </option>
          </b-select>
        </b-field>
        <div
          v-if="tlsOption >= 3"
          style="max-width:400px;margin: auto;"
        >
          <b-field
            v-for="field in truestdCNMaxLength"
            :key="field"
          >
            <b-input
              v-model="trustedCN[field - 1]"
              :placeholder="`${field === 1 ? '' : '(Optional) '}Remote Common Name`"
              @input="onChangeTrustedCN"
            />
          </b-field>
        </div>
        <p
          v-if="selectedDevices.length === 0"
        >
          We only will change the security of the computers that currently have TLS enabled
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vtulEnums from '../../../../cross/index';

export default {
  name: 'RestoreAmtTLS',
  props: {
    selectedAction: {
      type: Object,
      default() {
        return { };
      },
    },
    selectedDevices: {
      type: Array,
      default() {
        return [];
      },
    },
    onUpdate: {
      type: Function,
      default() { },
    },
  },
  data() {
    return {
      amtPassword: '',
      changeSecurityCheck: false,
      tlsOption: undefined,
      tlsOptions: this.generateTLSOptions(vtulEnums.enum.amt.amtTLS),
      trustedCN: ['', '', '', ''],
      truestdCNMaxLength: 4.0,
    };
  },
  computed: {
    ...mapGetters('amt-info', { findDeviceAMTInfoInStore: 'find' }),
    amtInfo() {
      if (this.selectedDevices.length !== 1) {
        return null;
      }
      const sList = this.findDeviceAMTInfoInStore({
        query: {
          id: this.selectedDevices[0].id,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });
      return (sList && sList.data) ? sList.data : null;
    },
  },
  watch: {
    selectedDevices() {
      this.getAmtInfo();
    },
  },
  created() {
    this.getAmtInfo();
  },
  methods: {
    ...mapActions('amt-info', { findDeviceAMTInfo: 'find' }),
    async getAmtInfo() {
      if (this.selectedDevices.length !== 1) {
        return null;
      }
      const sList = await this.findDeviceAMTInfo({
        query: {
          id: this.selectedDevices[0].id,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });
      return (sList && sList.data) ? sList.data : null;
    },
    updatePreferences(changeSecurityProp, newSecurityProp, commonNamesProp) {
      let changeSecurity = changeSecurityProp;
      let newSecurity = newSecurityProp;
      let commonNames = commonNamesProp;
      if (changeSecurity === null) {
        changeSecurity = this.changeSecurityCheck;
      }
      if (!newSecurity) {
        newSecurity = this.tlsOption;
      }
      if (!commonNames) {
        commonNames = this.trustedCN;
      }

      if (!changeSecurity) {
        newSecurity = undefined;
        this.tlsOption = newSecurity;
      }
      if (!newSecurity || newSecurity < 3) {
        commonNames = ['', '', '', ''];
        this.trustedCN = commonNames;
      }

      const finalCommonNames = commonNames.filter((x) => x !== '');
      this.onUpdate({
        changeSecurity,
        newSecurity,
        commonNames: finalCommonNames.length > 0
          ? finalCommonNames : undefined,
      });
    },
    onChangeCheckBox(checked) {
      this.updatePreferences(checked);
    },
    onChangeTLSOption(option) {
      this.updatePreferences(null, option);
    },
    onChangeTrustedCN() {
      this.updatePreferences(null, null, this.trustedCN);
    },
    generateTLSOptions(amtTLSEnum) {
      const options = [];

      Object.keys(amtTLSEnum).forEach((amtTLSKey) => {
        if (amtTLSEnum[amtTLSKey].value !== undefined
          && amtTLSEnum[amtTLSKey].value !== amtTLSEnum.NOT_SUPPORTED.value) {
          options.push({
            name: amtTLSEnum[amtTLSKey].value.name,
            value: amtTLSEnum[amtTLSKey].value.dbValue,
          });
        }
      });

      return options;
    },
  },
};
</script>
