<template>
  <section>
    <div style="text-align: center;">
      <div
        v-for="item in dataVisible"
        :key="item.value.name"
        class="action-card-content card material-card material-card-clickeable"
        @click="selectAction(item.value.id)"
      >
        <div
          class="device-info-title"
        >
          <b-icon
            :icon="item.value.icon"
          />
          <span
            class="action-card-title"
          >{{ item.value.name }}</span>
          <b-tag
            v-if="item.value.isvPro"
            class="vProTag"
            type="is-dark"
          >
            Requires AMT
          </b-tag>
        </div>
      </div>
    </div>
    <div v-show="filteredActionsTotal <= 0">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>Search for another action</p>
        </div>
      </section>
    </div>
    <b-pagination
      v-show="filteredActionsTotal > maxQuantity"
      :total="filteredActionsTotal"
      :current.sync="current"
      :per-page="maxQuantity"
      @change="changePage"
    />
  </section>
</template>
<script>

export default {
  name: 'ExecuteActionTab',
  props: {
    dataFiltered: {
      required: true,
      default: [],
      type: Array || Function,
    },
    selectAction: {
      required: true,
      default: () => {},
      type: Function,
    },
    maxQuantity: {
      required: false,
      default: 8,
      type: Number,
    },
    onChangePage: {
      default: () => {},
      type: Function,
    },
    currentPage: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      dataVisible: [],
      filteredActionsTotal: 0,
      current: this.currentPage || 1,
      skip: 0,
    };
  },
  watch: {
    dataFiltered() {
      this.changePage(this.current);
    },
  },
  created() {
    this.filteredActionsTotal = this.dataFiltered.length;
    this.changePage(this.current);
  },
  methods: {
    changePage(page) {
      if (this.filteredActionsTotal > this.maxQuantity) this.skip = (page - 1) * this.maxQuantity;
      this.current = page;
      this.dataVisible = this.dataFiltered.slice(this.skip, this.skip + this.maxQuantity);
      this.filteredActionsTotal = this.dataFiltered.length;
      this.onChangePage(page);
    },
  },
};
</script>
<style scoped>
.action-card-content {
  padding: 10px;
  margin: 4px 9px 4px 0px;
  display: inline-block;
  width: 196px;
  height: 100px;
}
.action-card-title {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25;
  width: 100%;
}
.device-info-title {
  margin-bottom: 0px !important;
  width: 100%;
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.vProTag {
  font-size: 10px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: white;
}
</style>
