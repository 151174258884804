<template>
  <div>
    <label>
      Parameters to execute Change Boot Type
    </label>
    <br>
    <br>
    <b-field
      v-if="showPassword"
      label="BIOS Password"
      position="is-left"
      :horizontal="true"
      class="force-parameters-right"
    >
      <b-input
        v-model="parameters.defaultPassword"
        placeholder="Current BIOS Password"
        type="text"
      />
    </b-field>
    <b-field
      label="New Boot type"
      position="is-left"
      :horizontal="showPassword"
      class="force-parameters-right"
    >
      <div :style="`display: inline-block;text-align: left;${showPassword ? 'width:100%' : ''}`">
        <div>
          <b-radio
            v-model="parameters.newBootMode"
            :native-value="bootTypeEnum.UEFI.value.dbValue"
            @input="onChangeState"
          >
            {{ bootTypeEnum.UEFI.value.name }}
          </b-radio>
        </div>
        <div>
          <b-radio
            v-model="parameters.newBootMode"
            :native-value="bootTypeEnum.LEGACY.value.dbValue"
            @input="onChangeState"
          >
            {{ bootTypeEnum.LEGACY.value.name }}
          </b-radio>
        </div>
      </div>
    </b-field>

    <b-input
      v-show="false"
      v-model="parameters.newBootMode"
      v-validate="'required|between:1,2'"
      name="newBootMode"
      type="newBootMode"
    />
    <span
      v-show="errors.has('newBootMode')"
      class="help is-danger"
    >{{ errors.first('newBootMode') }}</span>

    <p style="margin-top: 26px;">
      {{ currentValueString }}
    </p>
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

export default {
  name: 'ChangeBootType',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      bootTypeEnum: vtulEnums.enum.device.bootType,
      parameters: {
        newBootMode: undefined,
        defaultPassword: undefined,
      },
      disabledValue: undefined,
      currentValueString: '',
      showPassword: false,
    };
  },
  watch: {
    async selectedDevices() {
      this.getDisabledValue();
    },
  },
  mounted() {
    this.parent.$on('validateNewBootType', this.validateNewBootType);
  },
  async created() {
    this.onUpdateParameters(this.parameters);
    this.getDisabledValue();
  },
  methods: {
    getDisabledValue() {
      this.disabledValue = undefined;
      this.currentValueString = '';
      if (this.selectedDevices.length === 1) {
        const currentBootType = this.selectedDevices[0].osBootType;
        this.disabledValue = currentBootType;
        const currentValueStart = 'The current boot type is';
        const currentValueEnd = `in ${this.selectedDevices[0].name}`;
        switch (currentBootType) {
          case this.bootTypeEnum.LEGACY.value.dbValue:
            this.parameters.newBootMode = this.bootTypeEnum.UEFI.value.dbValue;
            this.currentValueString = `${currentValueStart} ${this.bootTypeEnum.LEGACY.value.name} ${currentValueEnd}.`;
            break;
          case this.bootTypeEnum.UEFI.value.dbValue:
            this.parameters.newBootMode = this.bootTypeEnum.LEGACY.value.dbValue;
            this.currentValueString = `${currentValueStart} ${this.bootTypeEnum.UEFI.value.name} ${currentValueEnd}.`;
            break;
          default:
            break;
        }
        this.showPassword = !this.selectedDevices[0].biosPassword;
        if (this.showPassword) {
          this.currentValueString += `\nWe do not know the BIOS password of ${this.selectedDevices[0].name}`;
        }
      }
    },
    onChangeState() {
      this.onUpdateParameters(this.parameters);
    },
    validateNewBootType(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>
