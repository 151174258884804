<template>
  <div>
    <label>
      Parameters to execute Change Secure boot
    </label>
    <br>
    <br>
    <b-field
      v-if="showPassword"
      label="BIOS Password"
      position="is-left"
      :horizontal="true"
      class="force-parameters-right"
    >
      <b-input
        v-model="parameters.defaultPassword"
        placeholder="Current BIOS Password"
        type="text"
      />
    </b-field>
    <b-field
      label="Secure boot"
      position="is-left"
      :horizontal="showPassword"
      class="force-parameters-right"
    >
      <div :style="`display: inline-block;text-align: left;${showPassword ? 'width:100%' : ''}`">
        <div>
          <b-radio
            v-model="parameters.newSecureBoot"
            :native-value="secureBootEnum.ENABLED.value.dbValue"
            @input="onChangeState"
          >
            {{ secureBootEnum.ENABLED.value.name }}
          </b-radio>
        </div>
        <div>
          <b-radio
            v-model="parameters.newSecureBoot"
            :native-value="secureBootEnum.DISABLED.value.dbValue"
            @input="onChangeState"
          >
            {{ secureBootEnum.DISABLED.value.name }}
          </b-radio>
        </div>
      </div>
    </b-field>

    <b-input
      v-show="false"
      v-model="parameters.newSecureBoot"
      v-validate="'required|between:1,2'"
      name="newSecureBoot"
      type="newSecureBoot"
    />
    <span
      v-show="errors.has('newSecureBoot')"
      class="help is-danger"
    >{{ errors.first('newSecureBoot') }}</span>

    <p style="margin-top: 26px;">
      {{ currentValueString }}
    </p>
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

export default {
  name: 'ChangeSecureBoot',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      secureBootEnum: vtulEnums.enum.device.secureBoot,
      parameters: {
        newSecureBoot: undefined,
        defaultPassword: undefined,
      },
      disabledValue: undefined,
      currentValueString: '',
      showPassword: false,
    };
  },
  watch: {
    async selectedDevices() {
      this.getDisabledValue();
    },
  },
  mounted() {
    this.parent.$on('validateNewSecureBoot', this.validateNewSecureBoot);
  },
  async created() {
    this.onUpdateParameters(this.parameters);
    this.getDisabledValue();
  },
  methods: {
    getDisabledValue() {
      this.disabledValue = undefined;
      this.currentValueString = '';
      if (this.selectedDevices.length === 1) {
        const currentSecureBoot = this.selectedDevices[0].secureBoot;
        this.disabledValue = currentSecureBoot;
        const currentValueStart = 'Secure boot currently is';
        const currentValueEnd = `in ${this.selectedDevices[0].name}`;
        switch (currentSecureBoot) {
          case this.secureBootEnum.ENABLED.value.dbValue:
            this.parameters.newSecureBoot = this.secureBootEnum.DISABLED.value.dbValue;
            this.currentValueString = `${currentValueStart} ${this.secureBootEnum.ENABLED.value.name} ${currentValueEnd}.`;
            break;
          case this.secureBootEnum.DISABLED.value.dbValue:
            this.parameters.newSecureBoot = this.secureBootEnum.ENABLED.value.dbValue;
            this.currentValueString = `${currentValueStart} ${this.secureBootEnum.DISABLED.value.name} ${currentValueEnd}.`;
            break;
          default:
            break;
        }
        this.showPassword = !this.selectedDevices[0].biosPassword;
        if (this.showPassword) {
          this.currentValueString += `\nWe do not know the BIOS password of ${this.selectedDevices[0].name}`;
        }
      }
    },
    onChangeState() {
      this.onUpdateParameters(this.parameters);
    },
    validateNewSecureBoot(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>
