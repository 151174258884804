<template>
  <div>
    <label>
      Parameters to execute {{ title }}
      <br>
      When do you want to execute the action
    </label>
    <br>
    <br>
    <section>
      <div>
        <b-radio
          v-model="now"
          :native-value="parametersEnum.NOW.value.id"
          @input="changeDisplay"
        >
          {{ parametersEnum.NOW.value.name }}
        </b-radio>
        <b-radio
          v-model="now"
          :native-value="parametersEnum.AFTER_SOME_TIME.value.id"
          @input="changeDisplay"
        >
          {{ parametersEnum.AFTER_SOME_TIME.value.name }}
        </b-radio>
      </div>
    </section>

    <br>

    <template>
      <b-field
        v-show="timeFieldsDisplay"
        class="is-justify-content-center center"
        grouped
      >
        <b-field expanded>
          <b-field>
            <b-input
              v-model="parameters.timeValue"
              v-validate="`regex:^[1-9]+|${fieldsRequired}`"
              class="center-input"
              style="max-width:70px;"
              placeholder="Value"
              name="value"
              type="number"
              expanded
              @input="changeParams"
            />
            <b-select
              v-model="parameters.timeUnit"
              v-validate="fieldsRequired"
              name="unit"
              placeholder="Time"
              @input="changeParams"
            >
              <option :value="unitTimeEnum.SECONDS.value.value">
                {{ unitTimeEnum.SECONDS.value.name }}
              </option>
              <option :value="unitTimeEnum.MINUTES.value.value">
                {{ unitTimeEnum.MINUTES.value.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>
      </b-field>
    </template>
    <div :style="timeFieldsDisplay">
      <span
        v-show="errors.has('unit')"
        class="help is-danger"
      >{{ errors.first('unit') }}</span>
      <br>
      <span
        v-show="errors.has('value')"
        class="help is-danger"
      >{{ errors.first('value') }}</span>
    </div>
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      unitTimeEnum: vtulEnums.enum.settings.unitsTime,
      parametersEnum: vtulEnums.enum.settings.parametersToExecuteWithDelay,
      parameters: {
        now: true,
        timeValue: 30,
        timeUnit: vtulEnums.enum.settings.unitsTime.SECONDS.value.value,
      },
      now: vtulEnums.enum.settings.parametersToExecuteWithDelay.NOW.value.id,
      timeFieldsDisplay: false,
      fieldsRequired: '',
    };
  },
  watch: {
    async created() {
      this.onUpdateParameters(this.parameters);
    },
  },
  mounted() {
    this.parent.$on('validateEmptyFields', this.validateEmptyFields);
  },
  created() {
    this.onUpdateParameters(this.parameters);
  },
  methods: {
    changeDisplay() {
      if (this.now === this.parametersEnum.NOW.value.id) {
        this.parameters.now = true;
        this.fieldsRequired = '';
        this.timeFieldsDisplay = false;
      } else {
        this.parameters.now = false;
        this.fieldsRequired = 'required';
        this.timeFieldsDisplay = true;
      }
    },
    changeParams() {
      this.parameters.now = false;
      this.onUpdateParameters(this.parameters);
      return true;
    },
    validateEmptyFields(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>
<style>
.center {
  display: grid;
}
.center-input input{
  text-align: center;
}
</style>
