var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',[_vm._v(" Parameters to execute "+_vm._s(_vm.title)+" "),_c('br'),_vm._v(" Warning: This action will delete all the content chosen from the chosen folder. ")]),_c('br'),_c('br'),_c('div',[_c('div',{staticClass:"option-container"},[_vm._m(0),_c('div',[_c('b-dropdown',{staticClass:"inner-element mt-2",attrs:{"aria-role":"list","position":'is-bottom-right',"scrollable":""},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{staticStyle:{"background-color":"#632d8e","color":"#fff","width":"200px"},attrs:{"label":_vm.parameters.folderToClean
                ? `${_vm.foldersToCleanArray.find(x =>
                  x.id === parseInt(_vm.parameters.folderToClean)).name} folder`
                :'folder',"icon-right":active ? 'menu-up' : 'menu-down'}})]}}]),model:{value:(_vm.parameters.folderToClean),callback:function ($$v) {_vm.$set(_vm.parameters, "folderToClean", $$v)},expression:"parameters.folderToClean"}},_vm._l((_vm.foldersToCleanArray),function(folder){return _c('b-dropdown-item',{key:folder.id,attrs:{"aria-role":"listitem","value":folder.id}},[_vm._v(" "+_vm._s(folder.name)+" folder ")])}),1)],1)]),_c('hr'),_c('div',{staticClass:"option-container"},[_vm._m(1),_c('div',[_c('b-dropdown',{staticClass:"inner-element mt-2",attrs:{"aria-role":"list","position":'is-bottom-right',"scrollable":""},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{staticStyle:{"background-color":"#632d8e","color":"#fff","width":"200px"},attrs:{"label":_vm.parameters.olderThan
                ? `Older than ${_vm.frequenciesArray.find(x =>
                  x.id === parseInt(_vm.parameters.olderThan)).name}`
                :'All files',"icon-right":active ? 'menu-up' : 'menu-down'}})]}}]),model:{value:(_vm.parameters.olderThan),callback:function ($$v) {_vm.$set(_vm.parameters, "olderThan", $$v)},expression:"parameters.olderThan"}},_vm._l((_vm.frequenciesArray),function(frequency){return _c('b-dropdown-item',{key:frequency.id,attrs:{"aria-role":"listitem","value":frequency.id}},[_vm._v(" "+_vm._s(_vm.getTextOptions(frequency))+" ")])}),1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("1. Select a folder to clean")]),_c('br'),_vm._v(" You can choose between different system folders to clean ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("2. Settings to clean the selected folder")]),_c('br'),_vm._v(" Choose the age of the files and the type of deletion ")])
}]

export { render, staticRenderFns }