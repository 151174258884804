<template>
  <div>
    <p><strong>New User Consent State</strong></p>
    <div style="display: inline-block;text-align: left;">
      <div class="field">
        <b-radio
          v-model="parameters.newState"
          :native-value="optInState.UNNECESSARY.value.dbValue"
          :disabled="disabledValue === optInState.UNNECESSARY.value.dbValue"
          @input="onChangeState"
        >
          Not required
        </b-radio>
      </div>
      <div class="field">
        <b-radio
          v-model="parameters.newState"
          :native-value="optInState.NECESSARY.value.dbValue"
          :disabled="disabledValue === optInState.NECESSARY.value.dbValue"
          @input="onChangeState"
        >
          Required for all operations
        </b-radio>
      </div>
    </div>
    <b-input
      v-show="false"
      v-model="parameters.newState"
      v-validate="'required|between:2,4'"
      name="newUserConsentState"
      type="newUserConsentState"
    />
    <span
      v-show="errors.has('newUserConsentState')"
      class="help is-danger"
    >{{ errors.first('newUserConsentState') }}</span>

    <p style="margin-top: 26px;">
      {{ currentValueString }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vtulEnums from '../../../../cross/index';

export default {
  name: 'ChangeOptInState',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      optInState: vtulEnums.enum.optIn.optInState,
      parameters: {
        newState: undefined,
      },
      disabledValue: undefined,
      currentValueString: '',
    };
  },
  computed: {
    ...mapGetters('amt-info', { findDeviceAMTInfoInStore: 'find' }),
    amtInfo() {
      if (this.selectedDevices.length !== 1) {
        return null;
      }
      const sList = this.findDeviceAMTInfoInStore({
        query: {
          id: this.selectedDevices[0].id,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });
      return (sList && sList.data) ? sList.data : null;
    },
  },
  watch: {
    async selectedDevices() {
      await this.getAmtInfo();
      this.getDisabledValue();
    },
  },
  mounted() {
    this.parent.$on('validateNewOptInState', this.validateNewOptInState);
  },
  async created() {
    await this.getAmtInfo();
    this.onUpdateParameters(this.parameters);
    this.getDisabledValue();
  },
  methods: {
    ...mapActions('amt-info', { findDeviceAMTInfo: 'find' }),
    async getAmtInfo() {
      if (this.selectedDevices.length !== 1) {
        return null;
      }
      const sList = await this.findDeviceAMTInfo({
        query: {
          id: this.selectedDevices[0].id,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });
      return (sList && sList.data) ? sList.data : null;
    },
    getDisabledValue() {
      if (!this.amtInfo || this.amtInfo.length !== 1 || this.selectedDevices.length > 1) {
        return;
      }

      this.disabledValue = undefined;
      this.currentValueString = '';

      const currentOptInState = this.amtInfo[0].optInState;
      this.disabledValue = currentOptInState;

      const currentValueStart = 'Currently the User Consent is';
      const currentValueEnd = `in ${this.selectedDevices[0].name}.`;
      switch (currentOptInState) {
        case 2:
          this.currentValueString = `${currentValueStart} not required ${currentValueEnd}`;
          break;
        case 3:
        case 4:
          this.currentValueString = `${currentValueStart} required for all operations ${currentValueEnd}`;
          break;
        default:
          break;
      }
    },
    onChangeState() {
      this.onUpdateParameters(this.parameters);
    },
    validateNewOptInState(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>
