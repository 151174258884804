<template>
  <div>
    <label>
      Parameters to execute {{ title }}
      <br>
      Warning: This action will delete all the content chosen from the chosen folder.
    </label>
    <br>
    <br>
    <div>
      <div class="option-container">
        <p>
          <strong>1. Select a folder to clean</strong><br>
          You can choose between different system folders to clean
        </p>
        <div>
          <b-dropdown
            v-model="parameters.folderToClean"
            aria-role="list"
            class="inner-element mt-2"
            :position="'is-bottom-right'"
            scrollable
          >
            <template #trigger="{ active }">
              <b-button
                style="background-color: #632d8e;color: #fff;width: 200px;"
                :label="parameters.folderToClean
                  ? `${foldersToCleanArray.find(x =>
                    x.id === parseInt(parameters.folderToClean)).name} folder`
                  :'folder'"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item
              v-for="folder in foldersToCleanArray"
              :key="folder.id"
              aria-role="listitem"
              :value="folder.id"
            >
              {{ folder.name }} folder
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <hr>
      <div class="option-container">
        <p>
          <strong>2. Settings to clean the selected folder</strong><br>
          Choose the age of the files and the type of deletion
        </p>
        <div>
          <b-dropdown
            v-model="parameters.olderThan"
            aria-role="list"
            class="inner-element mt-2"
            :position="'is-bottom-right'"
            scrollable
          >
            <template #trigger="{ active }">
              <b-button
                style="background-color: #632d8e;color: #fff;width: 200px;"
                :label="parameters.olderThan
                  ? `Older than ${frequenciesArray.find(x =>
                    x.id === parseInt(parameters.olderThan)).name}`
                  :'All files'"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item
              v-for="frequency in frequenciesArray"
              :key="frequency.id"
              aria-role="listitem"
              :value="frequency.id"
            >
              {{ getTextOptions(frequency) }}
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-dropdown
            v-model="parameters.typeOfDelete"
            aria-role="list"
            class="inner-element mt-2 ml-0"
            :position="'is-bottom-right'"
            scrollable
          >
            <template #trigger="{ active }">
              <b-button
                style="background-color: #632d8e;color: #fff;width: 200px;"
                :label="parameters.typeOfDelete
                  ? `${typeOfDeleteArray.find(x =>
                    x.id === parseInt(parameters.typeOfDelete)).name}`
                  :'Older than'"
                :icon-right="active ? 'menu-up' : 'menu-down'"
              />
            </template>
            <b-dropdown-item
              v-for="type in typeOfDeleteArray"
              :key="type.id"
              aria-role="listitem"
              :value="type.id"
            >
              {{ type.name }}
            </b-dropdown-item>
          </b-dropdown> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parseEnum from '@/helpers/parseEnums';
import vtulEnums from '../../../../cross/index';

const { frequencies, foldersToClean, parametersToDeleteFiles } = vtulEnums.enum.settings;

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      frequenciesArray: parseEnum.enumToArray(frequencies),
      foldersToCleanArray: parseEnum.enumToArray(foldersToClean),
      typeOfDeleteArray: parseEnum.enumToArray(parametersToDeleteFiles),
      parameters: {
        folderToClean: foldersToClean.DOWNLOADS.value.id,
        olderThan: frequencies.TWENTY_FOUR_HOURS.value.id,
        typeOfDelete: parametersToDeleteFiles
          .PERMANENTLY_DELETE.value.id,
      },
    };
  },
  watch: {
    async created() {
      this.onUpdateParameters(this.parameters);
    },
  },
  mounted() {
    this.parent.$on('validateEmptyDayField', this.validateEmptyDayField);
  },
  created() {
    this.onUpdateParameters(this.parameters);
  },
  methods: {
    changeParams() {
      this.parameters.allFiles = false;
      this.onUpdateParameters(this.parameters);
      return true;
    },
    validateEmptyDayField(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
    getTextOptions(frequency) {
      if (frequency.id === frequencies.OFF.value.id) return 'All files';
      const article = this.getRightFrequencyArticle(frequency.minutes) || '';
      return `Older than ${article} ${frequency.name}`;
    },
    getRightFrequencyArticle(frequency) {
      let text = '';
      if (frequency === frequencies.ONE_WEEK.value.minutes
      || frequency === frequencies.ONE_MONTH.value.minutes
      || frequency === frequencies.ONE_YEAR.value.minutes
      || frequency === frequencies.TWENTY_FOUR_HOURS.value.minutes) {
        text = 'a';
      } else if (frequency === frequencies.ONE_HOUR.value.minutes) {
        text = 'an';
      }
      return text;
    },
    filterFrequencies() {
      const filteredFrecuencies = this.frequenciesArray.filter(
        (frequency) => frequency.minutes >= frequencies.TWENTY_FOUR_HOURS.value.minutes
            && frequency.minutes <= frequencies.ONE_YEAR.value.minutes,
      );
      return filteredFrecuencies;
    },
  },
};
</script>
<style>
.center {
  display: grid;
}
.center-input input{
  text-align: center;
}

.option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.option-container p {
  text-align: start;
  margin-right: 1rem;
  width:400px;
}
</style>
