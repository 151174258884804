<template>
  <div class="distribute-container">
    <p class="distribute-title">
      {{ title }}
    </p>
    <span
      v-if="isSoftware"
      class="distribute-description"
    >
      This action allows you to distribute and execute your software to multiple devices at a time
      with custom parameters as you needed.
    </span>
    <span v-else>
      This action allows you to distribute your file to multiple devices at a time and extract them
      in the case is a .zip file.
    </span>
    <section class="columns mt-2">
      <div class="column is-8">
        <p>Choose a file</p>
        <span>Select the file to be uploaded and distributed to each device.</span>
      </div>
      <div
        class="column is-4"
        style="display: flex;"
      >
        <b-upload
          v-model="file"
          v-validate="'required'"
          required
          name="file"
          style="flex: auto"
          :disabled="isUploading"
          :class="isUploading ? 'upload-disabled' : ''"
          @input="onInputFile"
        >
          <a
            class="button is-primary"
            style="flex: auto;"
            :disabled="isUploading"
            icon-left="upload"
          >
            <b-icon
              class="file-icon"
              icon="upload"
            />
            <span
              class="file-label"
              style="overflow: hidden; white-space:nowrap; text-overflow:ellipsis;
              max-width: 140px;"
            >{{ file ? file.name : 'Choose a file' }}</span>
          </a>
        </b-upload>
        <button
          v-if="file"
          title="Clear selected file"
          class="button reset is-danger ml-1"
          :disabled="isUploading"
          @click="cleanParameters"
        >
          <b-icon icon="close" />
        </button>
      </div>
    </section>

    <section
      v-if="fileExt === 'zip' && !isSoftware"
      class="columns"
    >
      <div class="column is-8">
        <p>Extract files</p>
        <span>Do you want to extract the file in the destination folder? (this will delete the
          original .zip from the destination folder)
        </span>
      </div>
      <div
        class="column is-4"
        style="display: flex; justify-content:center;"
      >
        <b-field>
          <b-switch
            v-model="parameters.extract"
            @input="onUpdateParameters(parameters);"
          />
        </b-field>
      </div>
    </section>

    <section
      v-if="isSoftware"
      class="columns"
    >
      <div class="column is-8">
        <p>Run as user</p>
        <span>Do you want to execute the file in the user context? <br>Some applications only
          works well if are installed in this scenario</span>
      </div>
      <div
        class="column is-4"
        style="display: flex; justify-content:center;"
      >
        <b-switch
          v-model="parameters.runAsUser"
          @input="onUpdateParameters(parameters);"
        />
      </div>
    </section>

    <section
      v-if="!isSoftware"
      class="columns"
    >
      <div class="column is-8">
        <p>Destination folder</p>
        <span>Type the path where the file will be download in the destination device.
          (boardgent-temp by default)</span>
      </div>
      <div class="column is-4">
        <b-input
          v-model="parameters.folder"
          placeholder="boardgent-temp"
          style="width: 100%;"
          expanded
          @input="onUpdateParameters(parameters);"
        />
      </div>
    </section>

    <section
      v-if="isSoftware"
      class="columns"
    >
      <div class="column is-8">
        <p>Execution parameters</p>
        <span>Introduce any parameter that will be send in the file execution.</span>
      </div>
      <div class="column is-4">
        <b-input
          v-model="parameters.executionParams"
          style="width: 100%;"
          expanded
          placeholder="parameters"
          @input="onUpdateParameters(parameters);"
        />
      </div>
    </section>
    <section v-if="isUploading">
      <p>{{ uploadingTitle }}</p>
      <span class="mb-2">{{ uploadingMessage }}</span>
      <b-progress
        style="margin-top:1rem;"
        :value="uploadProgress"
        show-value
        format="percent"
        size="is-large"
        type="is-primary"
      />
    </section>
  </div>
</template>

<script>
import CompanyMixin from '@/mixins/company';
import pRetry from 'p-retry';
import CryptoJS from 'crypto-js';
import snackBarMessage from '@/helpers/snackBarMessage';
import EnvironmentSetter from '@/helpers/environmentSetter';

export default {
  name: 'DistributeOwnSoftware',
  mixins: [CompanyMixin],
  props: {
    isSoftware: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
    onExecutionFails: {
      type: Function,
      required: true,
      default() {},
    },
  },
  data() {
    return {
      limitMb: 1024,
      chunkSizeMb: 5,
      chunkIndex: 0,
      totalChunks: 0,
      chunksLoaded: [],
      agentServer: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_AGENT'),
      bgApi: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_API'),
      bgApiPath: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_VERSION_PATH'),
      file: null,
      isUploading: false,
      parameters: {
        url: '',
        folder: '',
        extract: '',
        execute: this.isSoftware ? true : '',
        runAsUser: '',
        executionParams: '',
      },
      uploadProgress: 0,
      uploadingTitle: 'Uploading',
      uploadingMessage: 'Please wait until the upload finish.',
    };
  },
  computed: {
    fileExt() {
      if (!this.file) return '';
      return this.file.name.split('.').pop();
    },
  },
  watch: {
    fileExt(newFileExt) {
      if (newFileExt === 'zip' && !this.isSoftware) {
        this.parameters.extract = true;
      }
    },
    isSoftware() {
      this.restartData();
    },
  },
  created() {
    this.onUpdateParameters(this.parameters);
  },
  mounted() {
    this.parent.$on('validateFile', this.validateFile);
  },
  methods: {
    async validateFile(actionId, companyId) {
      const result = await this.$validator.validateAll();
      await this.uploadFile();
      if (this.parameters.url !== '') {
        this.onResult(result, actionId, companyId, false);
      }
    },
    sliceFile(file) {
      let start = 0;
      const chunkSize = this.chunkSizeMb * 1024 * 1024;
      const slices = [];

      while (start < file.size) {
        const slice = file.slice(start, start + chunkSize);
        slices.push(slice);
        start += chunkSize;
      }

      return slices;
    },
    async getSha256(file) {
      let reader = new FileReader();

      const sha256 = await new Promise((resolve, reject) => {
        reader.onload = (event) => {
          const wordArray = CryptoJS.lib.WordArray.create(event.target.result);
          const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
          resolve(hash);
        };

        reader.onerror = (event) => {
          reject(event.target.error);
        };

        reader.readAsArrayBuffer(file);
      });

      reader = null;
      return sha256;
    },
    async processChunk(data) {
      const { chunk, fileName, apiUrl } = data;
      const sha256 = await this.getSha256(chunk);
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('index', this.chunkIndex.toString());
      formData.append('totalChunks', this.totalChunks.toString());
      formData.append('companyId', this.currentCompany.id);
      formData.append('fileName', fileName);
      formData.append('sha256', sha256);

      const axiosResponse = await this.axios.post(`${apiUrl}distributeownsoftware`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => this.increasePercentage(progressEvent),
      });
      return axiosResponse;
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }
      const attemptNumbers = 2;
      const chunks = this.sliceFile(this.file);
      const fileName = this.file ? this.file.name : '';
      this.totalChunks = chunks.length;
      this.chunkIndex = 0;
      try {
        const apiUrl = new URL(this.bgApi);
        apiUrl.pathname = this.bgApiPath;

        snackBarMessage.showSuccess('Uploading file...');
        let result;
        this.isUploading = true;

        // eslint-disable-next-line no-restricted-syntax
        for (const chunk of chunks) {
          let attempt = 0;
          // eslint-disable-next-line no-await-in-loop
          const processResult = await pRetry(() => (this.processChunk({
            chunk, fileName, apiUrl,
          })), {
            retries: attemptNumbers,
            minTimeout: 10 * 1000,
            maxTimeout: 20 * 1000,
            onFailedAttempt: async () => {
              if (attempt >= attemptNumbers) {
                await this.axios.post(`${apiUrl}deletedistributeownsoftware`, {
                  companyId: this.currentCompany.id,
                  totalChunks: this.chunkIndex,
                  fileName,
                });
              }
              attempt += 1;
            },
          });

          this.uploadProgress = ((this.chunkIndex + 1) * 100) / this.totalChunks;
          if (this.chunkIndex === this.totalChunks - 1) {
            result = processResult;
          }
          this.chunkIndex += 1;
        }

        if (result.status !== 200) {
          throw new Error(result.data.message);
        }
        this.parameters.url = result.data.url;
        this.onUpdateParameters(this.parameters);
      } catch (error) {
        snackBarMessage.showError('Unexpected error uploading the file');
        this.restartData();
        throw error;
      }
      this.isUploading = false;
    },
    cleanParameters() {
      this.file = null;
      this.parameters.url = '';
      this.parameters.extract = '';
      this.parameters.runAsUser = '';
      this.onUpdateParameters(this.parameters);
    },
    onInputFile() {
      if (this.file.size > this.limitMb * 1024 * 1024) {
        this.file = null;
        snackBarMessage.showError(`Currently we cannot send files larger than ${this.limitMb}mb`);
      }
      this.parameters.extract = '';
      this.parameters.runAsUser = '';
      this.onUpdateParameters(this.parameters);
    },
    increasePercentage(progressEvent) {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 100) / total);
      this.chunksLoaded[this.chunkIndex] = percentage;

      const totalPercentage = this.chunksLoaded.reduce(
        (sum, chunkPercentage) => sum + (chunkPercentage || 0), 0,
      ) / this.totalChunks;

      this.uploadProgress = totalPercentage;

      if (this.uploadProgress === 100) {
        this.uploadProgress = undefined;
        this.uploadingTitle = 'Processing';
        this.uploadingMessage = 'Please wait until the file is processed.';
      }
    },
    restartData() {
      this.onExecutionFails();
      this.file = null;
      this.isUploading = false;
      this.uploadProgress = 0;
      this.uploadingTitle = 'Uploading';
      this.uploadingMessage = 'Please wait until the upload finish.';
      this.parameters = {
        url: '',
        folder: '',
        extract: '',
        execute: this.isSoftware ? true : '',
        runAsUser: '',
        executionParams: '',
      };
      this.onUpdateParameters(this.parameters);
    },
  },
};
</script>

<style scoped>
.distribute-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
}

.distribute-container section {
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  padding: 1rem 0;
}

.distribute-container p {
  margin-bottom: 0;
  color: #4a4a4a;
}

.distribute-container .column:last-child {
  display: flex;
  align-items: center;
}

.distribute-title {
  font-size: 1.5rem;
  padding: 1rem 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.upload-disabled span{
  cursor: not-allowed;
}

.upload-disabled {
  opacity: 0.5;
}
</style>
