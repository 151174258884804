<template>
  <div>
    <b-field label="Current AMT Password">
      <b-input
        v-model="parameters.amtPassword"
        :placeholder="`${selectedDevices.length === 1
          && selectedDevices[0].amtStatus !== amtStatus.UNKNOW_CREDENTIALS.value.dbValue
          ? '(Optional) ' : ''}AMT Password`"
        type="text"
        @input="onPasswordChanged"
      />
    </b-field>
    <p>{{ getTextInformation }}</p>
    <div class="field">
      <b-checkbox
        v-model="parameters.enableRedirection"
        @input="onChangeEnableRedirction"
      >
        Enable KVM redirection after provisioning
      </b-checkbox>
    </div>
  </div>
</template>

<script>
import vtulEnums from '../../../../cross/index';

export default {
  name: 'RequestAmtPassword',
  props: {
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      amtStatus: vtulEnums.enum.amt.amtStatus,
      parameters: {
        amtPassword: '',
        enableRedirection: true,
      },
    };
  },
  computed: {
    getTextInformation() {
      if (this.selectedDevices.length === 1
        && this.selectedDevices[0].amtStatus !== this.amtStatus.UNKNOW_CREDENTIALS.value.dbValue) {
        return 'If you leave the password empty we will use the last known password.';
      }

      if (this.selectedDevices.length > 1) {
        return 'We will use this password for all selected devices.';
      }

      return '';
    },
  },
  created() {
    this.onUpdateParameters(this.parameters);
  },
  methods: {
    onPasswordChanged() {
      this.onUpdateParameters(this.parameters);
    },
    onChangeEnableRedirction() {
      this.onUpdateParameters(this.parameters);
    },
  },
};
</script>
