<template>
  <div>
    <label>
      Parameters to execute Change TPM Status
    </label>
    <br>
    <br>
    <b-field
      v-if="showPassword"
      label="BIOS Password"
      position="is-left"
      :horizontal="true"
      class="force-parameters-right"
    >
      <b-input
        v-model="parameters.defaultPassword"
        placeholder="Current BIOS Password"
        type="text"
      />
    </b-field>
    <b-field
      label="TPM Status"
      position="is-left"
      :horizontal="showPassword"
      class="force-parameters-right"
    >
      <div :style="`display: inline-block;text-align: left;${showPassword ? 'width:100%' : ''}`">
        <div>
          <b-radio
            v-model="parameters.newTpmStatus"
            :native-value="tpmStatusEnum.ENABLED.value.dbValue"
            @input="onChangeState"
          >
            {{ tpmStatusEnum.ENABLED.value.name }}
          </b-radio>
        </div>
        <div>
          <b-radio
            v-model="parameters.newTpmStatus"
            :native-value="tpmStatusEnum.DISABLED.value.dbValue"
            @input="onChangeState"
          >
            {{ tpmStatusEnum.DISABLED.value.name }}
          </b-radio>
        </div>
      </div>
    </b-field>

    <b-input
      v-show="false"
      v-model="parameters.newTpmStatus"
      v-validate="'required|between:1,2'"
      name="newTpmStatus"
      type="newTpmStatus"
    />
    <span
      v-show="errors.has('newTpmStatus')"
      class="help is-danger"
    >{{ errors.first('newTpmStatus') }}</span>

    <p style="margin-top: 26px;">
      {{ currentValueString }}
    </p>
  </div>
</template>

<script>
import bgEnums from '../../../../cross/index';

export default {
  name: 'ChangeSecureBoot',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      tpmStatusEnum: bgEnums.enum.device.tpmStatus,
      parameters: {
        newTpmStatus: undefined,
        defaultPassword: undefined,
      },
      disabledValue: undefined,
      currentValueString: '',
      showPassword: false,
    };
  },
  watch: {
    async selectedDevices() {
      this.getDisabledValue();
    },
  },
  mounted() {
    this.parent.$on('validateNewTpmStatus', this.validateNewTpmStatus);
  },
  async created() {
    this.onUpdateParameters(this.parameters);
    this.getDisabledValue();
  },
  methods: {
    getDisabledValue() {
      this.disabledValue = undefined;
      this.currentValueString = '';
      if (this.selectedDevices.length === 1) {
        const currentTpmStatus = this.selectedDevices[0].tpmStatus;
        this.disabledValue = currentTpmStatus;
        const currentValueStart = 'TPM is currently';
        const currentValueEnd = `in ${this.selectedDevices[0].name}`;
        switch (currentTpmStatus) {
          case this.tpmStatusEnum.ENABLED.value.dbValue:
            this.parameters.newTpmStatus = this.tpmStatusEnum.DISABLED.value.dbValue;
            this.currentValueString = `${currentValueStart} ${this.tpmStatusEnum.ENABLED.value.name} ${currentValueEnd}.`;
            break;
          case this.tpmStatusEnum.DISABLED.value.dbValue:
            this.parameters.newTpmStatus = this.tpmStatusEnum.ENABLED.value.dbValue;
            this.currentValueString = `${currentValueStart} ${this.tpmStatusEnum.DISABLED.value.name} ${currentValueEnd}.`;
            break;
          default:
            break;
        }
        this.showPassword = !this.selectedDevices[0].biosPassword;
        if (this.showPassword) {
          this.currentValueString += `\nWe do not know the BIOS password of ${this.selectedDevices[0].name}`;
        }
      }
    },
    onChangeState() {
      this.onUpdateParameters(this.parameters);
    },
    validateNewTpmStatus(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>
