<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item
          :to="{ name: 'devices'}"
          tag="router-link"
        >
          Managed devices
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-if="currentRouteName === 'executedevice' &&
            selectedDevicesAmount === 1 && currentSelectedDevice[0]"
          :to="{ name: 'device', params: { deviceId: currentSelectedDevice[0].id }}"
          tag="router-link"
        >
          {{ currentSelectedDevice[0].name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          Execute
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-content"
      internal-card="material-card material-card-content"
      url-help=""
    >
      <div
        slot="external-header"
        class="mb-3"
      >
        <p
          v-if="activeTab != 1"
        >
          Select the action that you want to execute in {{ selectedDevicesAmount === 1
            && currentSelectedDevice[0]
            ? currentSelectedDevice[0].name : `${selectedDevicesAmount} devices` }}
        </p>
        <p
          v-else
        >
          Execute {{ selectedAction.name }} in {{ selectedDevicesAmount === 1
            && currentSelectedDevice[0]
            ? currentSelectedDevice[0].name : `${selectedDevicesAmount} devices` }}
        </p>
      </div>
      <br>
      <b-tabs
        slot="body"
        v-model="activeTab"
        position="is-centered"
        class="padding-tabs"
        :animated="false"
      >
        <b-tab-item
          label="Select Action"
          class="margin-sections"
        >
          <b-input
            id="executeActionSearchInput"
            v-model="searchInput"
            v-focus
            icon="magnify"
            type="search"
            class="search-input"
            placeholder="Search..."
            autocomplete="off"
            @input="applyFilters"
          />
          <br>
          <b-tabs
            v-if="currentMembership && currentRole"
            v-model="activeTabAction"
            position="is-centered"
            class="padding-tabs"
            :animated="false"
            :multiline="true"
          >
            <b-tab-item
              v-if="filtersData[actionCategories.COMMANDS_AND_SCRIPTS.value].length > 0
                && validateIfHasPermission([
                  permissions.COMMANDS_AND_SCRIPTS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Commands & Scripts
                  <b-tag rounded>
                    {{ filtersData[actionCategories.COMMANDS_AND_SCRIPTS.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.COMMANDS_AND_SCRIPTS.value"
                :data-filtered="filtersData[actionCategories.COMMANDS_AND_SCRIPTS.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.GEOLOCATION_AND_LOCK.value].length > 0
                && validateIfHasPermission([
                  permissions.GEOLOCATION_AND_LOCK_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Geolocation & Lock
                  <b-tag rounded>
                    {{ filtersData[actionCategories.GEOLOCATION_AND_LOCK.value].length }}
                  </b-tag>
                </span>
              </template>

              <executeActionTab
                :key="actionCategories.GEOLOCATION_AND_LOCK.value"
                :data-filtered="filtersData[actionCategories.GEOLOCATION_AND_LOCK.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.OS_MANAGEMENT.value].length > 0
                && validateIfHasPermission([
                  permissions.OS_MANAGEMENT_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Operating system
                  <b-tag rounded>
                    {{ filtersData[actionCategories.OS_MANAGEMENT.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.OS_MANAGEMENT.value"
                :data-filtered="filtersData[actionCategories.OS_MANAGEMENT.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.LOCAL_PASSWORD.value].length > 0
                && validateIfHasPermission([
                  permissions.LOCAL_PASSWORD_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Local administrators
                  <b-tag rounded>
                    {{ filtersData[actionCategories.LOCAL_PASSWORD.value].length }}
                  </b-tag>
                </span>
              </template>

              <executeActionTab
                :key="actionCategories.LOCAL_PASSWORD.value"
                :data-filtered="filtersData[actionCategories.LOCAL_PASSWORD.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.ENCRYPTION.value].length > 0
                && validateIfHasPermission([
                  permissions.ENCRYPTION_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Encryption
                  <b-tag rounded>
                    {{ filtersData[actionCategories.ENCRYPTION.value].length }}
                  </b-tag>
                </span>
              </template>

              <executeActionTab
                :key="actionCategories.ENCRYPTION.value"
                :data-filtered="filtersData[actionCategories.ENCRYPTION.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.UPDATES.value].length > 0
                && validateIfHasPermission([
                  permissions.UPDATE_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Updates
                  <b-tag rounded>
                    {{ filtersData[actionCategories.UPDATES.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.UPDATES.value"
                :data-filtered="filtersData[actionCategories.UPDATES.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.SOFTWARE.value].length > 0
                && validateIfHasPermission([
                  permissions.SOFTWARE_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Software
                  <b-tag rounded>
                    {{ filtersData[actionCategories.SOFTWARE.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.SOFTWARE.value"
                :data-filtered="filtersData[actionCategories.SOFTWARE.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.BIOS_MANAGEMENT.value].length > 0
                && validateIfHasPermission([
                  permissions.BIOS_MANAGEMENT_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> BIOS
                  <b-tag rounded>
                    {{ filtersData[actionCategories.BIOS_MANAGEMENT.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.BIOS_MANAGEMENT.value"
                :data-filtered="filtersData[actionCategories.BIOS_MANAGEMENT.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.INVENTORY.value].length > 0
                && validateIfHasPermission([
                  permissions.INVENTORY_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Inventory
                  <b-tag rounded>
                    {{ filtersData[actionCategories.INVENTORY.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.INVENTORY.value"
                :data-filtered="filtersData[actionCategories.INVENTORY.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.NOTIFY.value].length > 0
                && validateIfHasPermission([
                  permissions.NOTIFY_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Notify
                  <b-tag rounded>
                    {{ filtersData[actionCategories.NOTIFY.value].length }}
                  </b-tag>
                </span>
              </template>

              <executeActionTab
                :key="actionCategories.NOTIFY.value"
                :data-filtered="filtersData[actionCategories.NOTIFY.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.POWER.value].length > 0
                && validateIfHasPermission([
                  permissions.POWER_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Power
                  <b-tag rounded>
                    {{ filtersData[actionCategories.POWER.value].length }}
                  </b-tag>
                </span>
              </template>

              <executeActionTab
                :key="actionCategories.POWER.value"
                :data-filtered="filtersData[actionCategories.POWER.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.AMT.value].length > 0
                && validateIfHasPermission([
                  permissions.AMT_MANAGEMENT_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> vPro AMT Management
                  <b-tag rounded>
                    {{ filtersData[actionCategories.AMT.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.AMT.value"
                :data-filtered="filtersData[actionCategories.AMT.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
            <b-tab-item
              v-if="filtersData[actionCategories.LOGS.value].length > 0
                && validateIfHasPermission([
                  permissions.LOGS_EXECUTIONS.value.dbValue
                ])"
              class="margin-sections"
            >
              <template slot="header">
                <span> Logs
                  <b-tag rounded>
                    {{ filtersData[actionCategories.LOGS.value].length }}
                  </b-tag>
                </span>
              </template>
              <executeActionTab
                :key="actionCategories.LOGS.value"
                :data-filtered="filtersData[actionCategories.LOGS.value]"
                :select-action="selectAction"
                :max-quantity="maxQuantityPerPage"
                :on-change-page="onChangePage"
                :current-page="currentActionTabPage"
              />
            </b-tab-item>
          </b-tabs>
        </b-tab-item>
        <b-tab-item
          :disabled="activeTab != 1"
          label="Confirm"
        >
          <div
            class="content has-text-grey has-text-centered"
            style="max-width:800px;margin:auto;"
          >
            <div v-if="selectedAction.parameters && selectedActionParameters">
              <label>
                {{ `Parameters to execute ${selectedAction.name}` }}
                <a
                  v-if="selectedActionHasAdvanceParameters"
                  label="Toggle"
                  type="is-primary"
                  size="is-medium"
                  @click="advanceOptions = !advanceOptions"
                >(Advanced)</a>
              </label>
              <br>
              <div
                v-if="selectedActionHasAdvanceParameters && advanceOptions"
                style="margin-top: 1em;"
              >
                <div class="notification">
                  <div class="content">
                    <div
                      v-for="parameter in selectedActionParameters.filter(
                        (action) => action.isAdvance)"
                      :key="parameter.name"
                    >
                      <div
                        :key="parameter.name"
                        :label="parameter.label ? parameter.label : ''"
                        :type="errors.has(parameter.name) ? 'is-danger' : ''"
                        :class="'force-parameters-center'"
                      >
                        <b-checkbox
                          v-model="parameter.checkbox.default"
                          :expanded="false"
                          style="margin-bottom: 10px;"
                        >
                          {{ parameter.checkbox.checkboxText }}
                          <b-tooltip
                            label="Select this option if you need to download a specific
                                version available from Boardgent"
                            position="is-right"
                            multilined
                            size="is-large"
                            type="is-dark"
                          >
                            <b-icon
                              icon="help-circle"
                              size="is-small"
                            />
                          </b-tooltip>
                        </b-checkbox>
                        <b-dropdown
                          v-if="parameter.checkbox.default"
                          v-model="parameter.default"
                          v-validate="'required'"
                          :name="parameter.name"
                          aria-role="list"
                          class="inner-element mt-2"
                          position="is-bottom-right"
                          scrollable
                        >
                          <template #trigger="{ active }">
                            <b-button
                              :label="parameter.default ?
                                parameter.options.find((x) => x.id === parameter.default).name
                                : parameter.placeholder"
                              :icon-right="active ? 'menu-up' : 'menu-down'"
                            />
                          </template>
                          <b-dropdown-item
                            v-for="option in parameter.options"
                            :key="option.id"
                            :value="option.id"
                            aria-role="listitem"
                          >
                            {{ option.text }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <p
                        v-show="parameter.checkbox.default && errors.has(parameter.name)"
                        style="font-size: 13px;color: #632d8e;"
                      >
                        {{ errors.first(parameter.name) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <b-tooltip
                v-show="selectedActionHasOptionalParameters"
                label="Is necessary to provide account credentials if Boardgent does not manage
                local account."
              >
                <b-checkbox
                  v-if="selectedActionHasOptionalParameters"
                  v-model="showOptionalParameters"
                  style="margin-bottom: 2em;"
                >
                  (Optional) Provide account credentials to disable FileVault.
                </b-checkbox>
              </b-tooltip>
              <div
                v-for="parameter in selectedActionParameters.filter(
                  (action) => !action.isAdvance)"
                :key="parameter.name"
              >
                <b-field
                  v-if="!selectedActionHasOptionalParameters
                    || (selectedActionHasOptionalParameters && showOptionalParameters)"
                  :key="parameter.name"
                  :label="parameter.label ? parameter.label : ''"
                  :position="parameter.position ? parameter.position : 'is-left'"
                  :horizontal="parameter.horizontal ? parameter.horizontal : false"
                  :type="errors.has(parameter.name) ? 'is-danger' : ''"
                  :class="parameter.horizontal ? 'force-parameters-right' : ''"
                >
                  <b-input
                    v-if="parameter.type === 'text' || parameter.type === 'textarea'
                      || parameter.type === 'number' || parameter.type === 'password'"
                    v-model="parameter.default"
                    v-validate="parameter.validate ? parameter.validate : ''"
                    :name="parameter.name"
                    :placeholder="parameter.placeholder ? parameter.placeholder : ''"
                    :type="parameter.type"
                    :password-reveal="parameter.type === 'password'"
                  />
                  <b-checkbox
                    v-else-if="parameter.type === 'checkbox'"
                    v-model="parameter.default"
                    v-validate="parameter.validate ? parameter.validate : ''"
                    :name="parameter.name"
                  >
                    {{ parameter.checkboxText }}
                  </b-checkbox>
                </b-field>
                <p
                  v-show="errors.has(parameter.name)"
                  style="font-size: 13px;color: #632d8e;"
                >
                  {{ errors.first(parameter.name) }}
                </p>
              </div>
            </div>
            <div v-if="selectedAction.requestAmtPassword">
              <request-amt-password
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.restoreTLS">
              <restore-amt-tls
                :selected-action="selectedAction"
                :on-update="updateParameters"
                :selected-devices="currentSelectedDevice"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.changeOptInState">
              <change-opt-in-state
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.changeAmtWirelessStatus">
              <change-amt-wireless-state
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.changeLockStatus">
              <change-lock-status
                :parent="this"
                :on-update-parameters="updateParameters"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.selectEventLog">
              <select-event-log
                :parent="this"
                :on-update-parameters="updateParameters"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.changeBootType">
              <change-boot-type
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.lockDevice">
              <lock-device-parameters
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.changeSecureBoot">
              <change-secure-boot
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.changeTpmStatus">
              <change-tpm-status
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.powerAction">
              <power-execution-params
                :title="selectedAction.name"
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.cleanFolders">
              <delete-files-from-folder
                :title="selectedAction.name"
                :parent="this"
                :on-update-parameters="updateParameters"
                :selected-devices="currentSelectedDevice"
                :on-result="onResultBeforeExecuteAction"
              />
              <br>
            </div>
            <div v-else-if="selectedAction.distributeOwnSoftware">
              <distribute-own-software
                :title="selectedAction.name"
                :parent="this"
                :on-update-parameters="updateParameters"
                :on-result="onResultBeforeExecuteAction"
                :is-software="selectedAction.id === executionsEnum.DISTRIBUTE_SOFTWARE.value.id"
                :on-execution-fails="onExecutionFails"
              />
              <br>
            </div>
            <div v-else>
              <b-icon
                v-if="selectedAction.warningMessage"
                icon="alert"
              />
              <p>
                Are you sure to execute <b>{{ selectedAction.name }}</b> on
                <b v-if="selectedDevices.length === 0">
                  {{ selectedDevices.length + ' devices' }}
                </b>
                <b v-else-if="selectedDevices.length === 1 && currentSelectedDevice[0]">
                  {{ currentSelectedDevice[0].name }}
                </b>
                <b v-else>
                  {{ selectedDevices.length + ' devices' }}
                </b>
                ?
                <template v-if="selectedAction.warningMessage">
                  <br>
                  <b> Warning: </b>
                  {{ selectedAction.warningMessage }}
                </template>
                <template v-else-if="selectedAction.informativeMessage">
                  <br>
                  {{ selectedAction.informativeMessage }}
                </template>
              </p>
            </div>
            <div
              v-if="selectedAction.serverSide"
              class="field"
            >
              Intel® AMT actions are only executed in devices that are connected now.
            </div>
            <div
              v-if="selectedAction.serverAndClientSide"
              class="field"
            >
              This action works with online and offline devices.
            </div>
            <div
              v-else
              class="field"
              style="margin-top: 30px;"
            >
              <b-checkbox v-model="onlyOnlineDevices">
                Run only in online devices
              </b-checkbox>
            </div>
            <div>
              <button
                class="button margin-buttons"
                @click="activeTab = 0"
              >
                <span>Return</span>
              </button>
              <button
                :class="{'is-loading': onExecution }"
                class="button is-primary margin-buttons"
                @click="beforeExecuteAction(selectedAction, currentCompany.id)"
              >
                <b-icon
                  icon="lightning-bolt"
                />
                <span>Run</span>
              </button>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Fuse from 'fuse.js';

import snackBarMessage from '@/helpers/snackBarMessage';

import requestAmtPassword from '@/components/executions/RequestAmtPassword.vue';
import restoreAmtTLS from '@/components/executions/RestoreAmtTLS.vue';
import card from '@/components/cross/Card.vue';
import executeActionTab from '@/components/executions/ExecuteActionTab.vue';
import changeOptInState from '@/components/executions/ChangeOptInState.vue';
import changeAmtWirelessStatus from '@/components/executions/ChangeAmtWirelessStatus.vue';
import changeLockStatus from '@/components/executions/ChangeLockStatus.vue';
import selectEventLog from '@/components/executions/SelectEventLog.vue';
import changeBootType from '@/components/executions/ChangeBootType.vue';
import changeSecureBoot from '@/components/executions/ChangeSecureBoot.vue';
import changeTpmStatus from '@/components/executions/ChangeTpmStatus.vue';
import powerExecutionParams from '@/components/executions/powerExecutionParams.vue';
import LockDeviceParameters from '@/components/executions/LockDeviceParameters.vue';
import deleteFilesFromFolder from '@/components/executions/deleteFilesFromFolder.vue';
import distributeOwnSoftware from '@/components/executions/distributeOwnSoftware.vue';

import groupsExecution from '@/mixins/executions';
import CompanyMixin from '@/mixins/company';
import RoleMixin from '@/mixins/roles';
import vtulEnums from '../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { permissions } = vtulEnums.enum.roles;

const fuseOptions = {
  findAllMatches: true,
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['value.name', 'value.help'],
};
export default {
  name: 'ExecuteAction',
  components: {
    'request-amt-password': requestAmtPassword,
    'restore-amt-tls': restoreAmtTLS,
    executeActionTab,
    'change-opt-in-state': changeOptInState,
    'change-amt-wireless-state': changeAmtWirelessStatus,
    'change-lock-status': changeLockStatus,
    'select-event-log': selectEventLog,
    'change-boot-type': changeBootType,
    'change-secure-boot': changeSecureBoot,
    'change-tpm-status': changeTpmStatus,
    'power-execution-params': powerExecutionParams,
    card,
    'lock-device-parameters': LockDeviceParameters,
    'delete-files-from-folder': deleteFilesFromFolder,
    'distribute-own-software': distributeOwnSoftware,
  },
  mixins: [groupsExecution, CompanyMixin, RoleMixin],
  data() {
    return {
      maxQuantityPerPage: 12,
      executionsActions: vtulEnums.enum.execution.executeAction.enums
        .filter((x) => x.value.isVisibleAction),
      executionsEnum: vtulEnums.enum.execution.executeAction,
      searchInput: '',
      selectedAction: {},
      selectedActionParameters: undefined,
      parametersToExecute: undefined,
      activeTab: 0,
      activeTabAction: this.$route.params.actionTab !== undefined
        ? parseInt(this.$route.params.actionTab, 10) : 0,
      onExecution: false,
      onlyOnlineDevices: true,
      showSelectedDevices: false,
      actionCategories: vtulEnums.enum.action.actionCategory,
      fuseOptions,
      filtersData: [],
      fuse: null,
      currentActionTabPage: this.$route.params.tabPage !== undefined
        ? parseInt(this.$route.params.tabPage, 10) : 1,
      permissions,
      selectedDevices: [],
      selectedDevicesAmount: 0,
      currentSelectedDevice: [],
      advanceOptions: false,
      selectedActionHasAdvanceParameters: false,
      selectedActionHasOptionalParameters: false,
      showOptionalParameters: false,
    };
  },
  computed: {
    ...mapGetters('devices', ['getSelectedDevices']),
    ...mapGetters('roles', { currentUserRole: 'getCurrentRole' }),
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    activeTabAction() {
      this.currentActionTabPage = 1;
      this.$router.replace({ params: { actionTab: this.activeTabAction, tabPage: 1 } });
    },
  },
  async created() {
    this.selectedDevices = this.getSelectedDevices(this.currentCompany.id).selectedDevices;
    this.selectedDevicesAmount = this.selectedDevices.length;
    if (this.selectedDevicesAmount === 1) {
      this.getCurrentDevice();
    }
    let maxQuantityPerPage = 12;
    if (window.innerWidth > 1166) {
      maxQuantityPerPage = 15;
    } else if (window.innerWidth < 517) {
      maxQuantityPerPage = 8;
    }
    this.maxQuantityPerPage = maxQuantityPerPage;
    this.executionsActions.map((action) => {
      const element = action;
      if (element.value.isvPro) {
        element.value.help += ' amt vpro';
      }
      return true;
    });
    this.fuse = new Fuse(this.executionsActions, this.fuseOptions);
    this.applyFilters();
  },
  methods: {
    ...mapActions('devices', { getDevice: 'get' }),
    selectAction(id) {
      const action = this.executionsActions.find((x) => x.value.id === id);
      // We want to select value of enum.
      this.selectedAction = action.value;
      this.selectedActionParameters = action.value.parameters ? action.value.parameters : undefined;
      this.selectedActionHasAdvanceParameters = this.selectedActionParameters
        && this.selectedActionParameters.some((currentAction) => currentAction.isAdvance);
      this.selectedActionHasOptionalParameters = this.selectedActionParameters
        && this.selectedActionParameters.some((currentAction) => currentAction.optional);
      if (this.selectedActionParameters) {
        const restartParamIndex = this.selectedActionParameters.findIndex((x) => x.isRestartCheck);
        if (restartParamIndex !== -1) {
          this.selectedActionParameters[restartParamIndex] = {
            ...this.selectedActionParameters[restartParamIndex],
            default: false,
          };
        }
      }
      this.activeTab = 1;
      this.showSelectedDevices = false;
    },
    compare(x, y) {
      if (x.value.order == null || y.value.order == null) return 0;
      if (x.value.order > y.value.order) return 1;
      return -1;
    },
    beforeExecuteAction(selectedAction, companyId) {
      if (this.selectedDevices.length < 1) {
        snackBarMessage.showError(operationalUiErrors.UNEXPECTED_ERROR.value.message);
        return;
      }

      this.onExecution = true;
      if (selectedAction.uploadAmtFile) {
        this.$emit('uploadAmtProfile', selectedAction.id, companyId);
      } else if (selectedAction.changeOptInState) {
        this.$emit('validateNewOptInState', selectedAction.id, companyId);
      } else if (selectedAction.changeAmtWirelessStatus) {
        this.$emit('validateNewAMTWirelessStatus', selectedAction.id, companyId);
      } else if (selectedAction.changeLockStatus) {
        this.$emit('validateLockStatusInput', selectedAction.id, companyId);
      } else if (selectedAction.selectEventLog) {
        this.$emit('validateWindowsEventLogsInput', selectedAction.id, companyId);
      } else if (selectedAction.changeBootType) {
        this.$emit('validateNewBootType', selectedAction.id, companyId);
      } else if (selectedAction.changeSecureBoot) {
        this.$emit('validateNewSecureBoot', selectedAction.id, companyId);
      } else if (selectedAction.changeTpmStatus) {
        this.$emit('validateNewTpmStatus', selectedAction.id, companyId);
      } else if (selectedAction.powerAction) {
        this.$emit('validateEmptyFields', selectedAction.id, companyId);
      } else if (selectedAction.lockDevice) {
        this.$emit('validateLockDevice', selectedAction.id, companyId);
      } else if (selectedAction.cleanFolders) {
        this.$emit('validateEmptyDayField', selectedAction.id, companyId);
      } else if (selectedAction.distributeOwnSoftware) {
        this.$emit('validateFile', selectedAction.id, companyId);
      } else if (selectedAction.parameters && this.selectedActionParameters) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.parametersToExecute = undefined;
            this.executeAction(selectedAction.id, companyId);
          } else {
            this.onExecution = false;
          }
        }).catch((error) => { throw new Error(error); });
      } else {
        this.parametersToExecute = undefined;
        this.executeAction(selectedAction.id, companyId);
      }
    },
    onResultBeforeExecuteAction(result, actionId, companyId, showError) {
      if (result) {
        this.executeAction(actionId, companyId);
        return;
      }
      if (showError) {
        snackBarMessage.showError(operationalUiErrors.DATA_VALIDATION_ERROR.value.message);
      }
      this.onExecution = false;
    },
    onUploadAmtProfile(result, actionId, companyId) {
      if (result) {
        this.executeAction(actionId, companyId);
      } else {
        this.onExecution = false;
        snackBarMessage.showError(operationalUiErrors.UNEXPECTED_UPLOAD_ERROR.value.message);
      }
    },
    updateParameters(actionParameters) {
      this.parametersToExecute = actionParameters;
    },
    applyFilters() {
      let data = this.executionsActions.filter(
        (action) => this.validateIfHasCapability(action.value.requiredCapability),
      );
      if (this.searchInput !== '') {
        data = this.fuse.search(this.searchInput);
      }
      this.actionCategories.enums.forEach((element) => {
        this.filtersData[element.value] = data.filter(
          (action) => action.value.category === element.value
          && (!this.$isBgCloud || (this.$isBgCloud && !action.value.onlyForOnPremise)),
        ).sort(this.compare);
        return true;
      });
    },
    onChangePage(currentPage) {
      this.currentActionTabPage = currentPage;
      this.$router.replace({ params: { actionTab: this.activeTabAction, tabPage: currentPage } });
    },
    async getCurrentDevice() {
      this.currentSelectedDevice = [await this.getDevice([this.selectedDevices[0], {
        query: {
          companyId: this.currentCompany.id,
        },
      }])];
    },
    onExecutionFails() {
      this.onExecution = false;
    },
  },
};
</script>

<style scoped>
.search-input {
  margin: 20px 0px 5px 0px;
}
.padding-tabs {
  padding: 0px 1rem;
}
.margin-sections {
  margin: 0px -1rem;
}
.force-parameters-right {
  text-align: initial !important;
}

.force-parameters-center {
  align-items: center;
  display: flex !important;
  flex-direction: column !important;
}
.margin-buttons {
  margin: 6px;
}

.inner-element {
  display: block !important;
  margin-left: 55px;
  text-align: left !important;
}
</style>
