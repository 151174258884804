<template>
  <div>
    <b-field
      label="Parameters to execute get windows event logs"
    />
    <div style="display: flex;">
      <b-field
        :label="enumEventLogFilters.LOG_NAME.value.name"
        position="is-left"
        :type="errors.has('log-name') ? 'is-danger' : ''"
        class="text-auto"
      />
      <learn-more
        url="https://help.boardgent.com/articles/4816676-how-to-get-the-names-for-logname"
        help-text="Learn more about LogNames."
        position="is-bottom"
        :is-multiline="true"
      />
      <b-input
        v-model="windowsEventLogs.logName"
        v-validate="'required'"
        name="log-name"
        :placeholder="enumEventLogFilters.LOG_NAME.value.name"
        type="text"
        class="text-auto"
        style="width: 100%;"
        @input="onChangeParameters()"
      />
    </div>
    <div
      class="field"
      style="margin-top: 21px;"
    >
      <b-switch
        v-model="windowsEventLogs.allData"
        @input="onChangeParameters()"
      >
        Show detailed log
      </b-switch>
    </div>
    <span
      v-show="errors.has(`log-name`)"
      class="help is-danger"
    >The name field can not be empty.</span>
    <p
      style="font-weight: 600; padding-top: 23px;"
    >
      Select a new filter for Get-EventLog command (Optional)
    </p>
    <div
      v-for="(field, index) in windowsEventLogs.names"
      :key="index"
    >
      <b-field
        position="is-left"
        :horizontal="true"
        :label="field"
        :type="errors.has(`eventLog${index}`) ? 'is-danger':''"
        class="text-auto"
      >
        <b-input
          v-if="field === enumEventLogFilters.NEWEST.value.command
            || field === enumEventLogFilters.INSTANCE_ID.value.command"
          v-model="windowsEventLogs.filters[index]"
          v-validate="'required'"
          :name="`eventLog${index}`"
          type="number"
          @input="onChangeParameters()"
        />
        <b-datetimepicker
          v-else-if="field === enumEventLogFilters.AFTER.value.command
            || field === enumEventLogFilters.BEFORE.value.command"
          v-model="windowsEventLogs.filters[index]"
          v-validate="'required'"
          :name="`eventLog${index}`"
          placeholder="Select a date"
          icon="calendar-today"
          :horizontal-time-picker="true"
          :editable="true"
          :mobile-native="false"
          :append-to-body="true"
          @input="onChangeParameters()"
        />
        <b-input
          v-else
          v-model="windowsEventLogs.filters[index]"
          v-validate="'required'"
          :name="`eventLog${index}`"
          type="text"
          @input="onChangeParameters()"
        />
      </b-field>
      <span
        v-show="errors.has(`eventLog${index}`)"
        class="help is-danger"
      >The filter field can not be empty.</span>
    </div>
    <div
      v-show="windowsEventLogs.names.length === 0"
      style="margin: 12px 0px;text-align:center"
    >
      <p style="font-weight: 100;">
        There are not windows event logs filters selected.
      </p>
    </div>
    <b-dropdown
      v-model="windowsEventLogs.names"
      :scrollable="true"
      max-height="144px"
      style="text-align: left;"
      aria-role="list"
      multiple
    >
      <template #trigger="{ active }">
        <b-button
          :icon-right="active ? 'menu-up' : 'menu-down'"
          class="button colors: is-primary "
          style="margin-top: 12px;"
        >
          Add Filter ({{ windowsEventLogs.names.length }} Selected)
        </b-button>
      </template>

      <b-dropdown-item
        v-for="filter in eventLogsFilters"
        :key="filter.command"
        :value="filter.command"
        aria-role="listitem"
        @click="onChangeParameters()"
      >
        <span>{{ filter.name }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import crossHelper from '@/helpers/cross';
import learnMore from '@/components/cross/LearnMore.vue';
import { filters } from '../../../../cross/src/windowsEventLogs.enum';

export default {
  name: 'SelectEventLog',
  components: {
    learnMore,
  },
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      windowsEventLogs: { logName: '', names: [], filters: [] },
      enumEventLogFilters: filters,
      eventLogsFilters: (Object.keys(filters).map((filter) => (filters[filter].value || ' ')).filter((filter) => filter !== ' ' && filter.name !== filters.LOG_NAME.value.name)),
      crossHelper,
    };
  },
  mounted() {
    this.parent.$on('validateWindowsEventLogsInput', this.validateWindowsEventLogsInput);
  },
  methods: {
    onChangeParameters() {
      // The values with the ($) change according the filter
      const windowsEventLogsParameters = {
        logName: { name: this.windowsEventLogs.logName, command: filters.LOG_NAME.value.command },
        filters: [],
        allData: this.windowsEventLogs.allData,
      };
      this.windowsEventLogs.names.forEach((name, index) => {
        windowsEventLogsParameters.filters.push(
          {
            name,
            value: this.windowsEventLogs.filters[index],
          },
        );
      });
      this.onUpdateParameters(windowsEventLogsParameters);
    },
    validateWindowsEventLogsInput(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>

<style scoped>
.text-auto {
  text-align: initial !important;
}
.field-group {
  margin-bottom: 12px;
}
.internal-field-group {
  margin-bottom: 0px;
}
</style>
