<template>
  <div>
    <p><strong>New AMT Wireless Status</strong></p>
    <div style="display: inline-block;text-align: left;">
      <div class="field">
        <b-radio
          v-model="parameters.newState"
          :native-value="amtWirelessStatus.DISABLED.value.dbValue"
          :disabled="disabledValue === amtWirelessStatus.DISABLED.value.dbValue"
          @input="onChangeState"
        >
          Disabled
        </b-radio>
      </div>
      <div class="field">
        <b-radio
          v-model="parameters.newState"
          :native-value="amtWirelessStatus.ENABLED_TURNED_ON.value.dbValue"
          :disabled="disabledValue === amtWirelessStatus.ENABLED_TURNED_ON.value.dbValue"
          @input="onChangeState"
        >
          Enabled when the device is turned on
        </b-radio>
      </div>
      <div class="field">
        <b-radio
          v-model="parameters.newState"
          :native-value="amtWirelessStatus.ENABLED_TURNED_ON_AND_SLEEP.value.dbValue"
          :disabled="disabledValue === amtWirelessStatus.ENABLED_TURNED_ON_AND_SLEEP.value.dbValue"
          @input="onChangeState"
        >
          Enabled when the device is turned on and sleep (Only when connected to AC)
        </b-radio>
      </div>
    </div>

    <b-input
      v-show="false"
      v-model="parameters.newState"
      v-validate="'required|between:2,4'"
      name="newAmtWirelessStatus"
      type="newAmtWirelessStatus"
    />
    <span
      v-show="errors.has('newAmtWirelessStatus')"
      class="help is-danger"
    >{{ errors.first('newAmtWirelessStatus') }}</span>

    <p style="margin-top: 26px;">
      {{ currentValueString }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vtulEnums from '../../../../cross/index';

export default {
  name: 'ChangeAMTWirelessStatus',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
  },
  data() {
    return {
      amtWirelessStatus: vtulEnums.enum.amt.amtWirelessStatus,
      parameters: {
        newState: undefined,
      },
      disabledValue: undefined,
      currentValueString: '',
    };
  },
  computed: {
    ...mapGetters('amt-info', { findDeviceAMTInfoInStore: 'find' }),
    amtInfo() {
      if (this.selectedDevices.length !== 1) {
        return null;
      }
      const sList = this.findDeviceAMTInfoInStore({
        query: {
          id: this.selectedDevices[0].id,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });
      return (sList && sList.data) ? sList.data : null;
    },
  },
  watch: {
    async selectedDevices() {
      await this.getAmtInfo();
      this.getDisabledValue();
    },
  },
  mounted() {
    this.parent.$on('validateNewAMTWirelessStatus', this.validateNewAMTWirelessStatus);
  },
  async created() {
    await this.getAmtInfo();
    this.onUpdateParameters(this.parameters);
    this.getDisabledValue();
  },
  methods: {
    ...mapActions('amt-info', { findDeviceAMTInfo: 'find' }),
    async getAmtInfo() {
      if (this.selectedDevices.length !== 1) {
        return null;
      }
      const sList = await this.findDeviceAMTInfo({
        query: {
          id: this.selectedDevices[0].id,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });
      return (sList && sList.data) ? sList.data : null;
    },
    getDisabledValue() {
      if (!this.amtInfo || this.amtInfo.length !== 1 || this.selectedDevices.length > 1) {
        return;
      }

      this.disabledValue = undefined;
      this.currentValueString = '';

      const currentWirelessState = this.amtInfo[0].wirelessState;
      this.disabledValue = currentWirelessState;

      const currentValueStart = 'Currently the AMT wireless is';
      const currentValueEnd = `in ${this.selectedDevices[0].name}`;
      switch (currentWirelessState) {
        case this.amtWirelessStatus.DISABLED.value.dbValue:
          this.currentValueString = `${currentValueStart} disabled ${currentValueEnd}.`;
          break;
        case this.amtWirelessStatus.ENABLED_TURNED_ON.value.dbValue:
          this.currentValueString = `${currentValueStart} enabled when turned on ${currentValueEnd}.`;
          break;
        case this.amtWirelessStatus.ENABLED_TURNED_ON_AND_SLEEP.value.dbValue:
          this.currentValueString = `${currentValueStart} enabled when turned on and sleep (only when connected to AC) ${currentValueEnd}.`;
          break;
        default:
          break;
      }
    },
    onChangeState() {
      this.onUpdateParameters(this.parameters);
    },
    validateNewAMTWirelessStatus(actionId, companyId) {
      this.$validator.validateAll().then((result) => {
        this.onResult(result, actionId, companyId, false);
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>
