<template>
  <div>
    <p
      v-if="isFromModal"
      class="subtitle"
    >
      <b>{{ title }}</b>
    </p>
    <p
      v-else
    >
      {{ title }}
    </p>
    <p>This action will lock the device and prevent it from being used until unlocked.</p>
  </div>
</template>

<script>

export default {
  name: 'LockDeviceParameters',
  props: {
    parent: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    selectedDevices: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    onResult: {
      type: Function,
      required: true,
      default() { },
    },
    onUpdateParameters: {
      type: Function,
      required: true,
      default() { },
    },
    isFromModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: 'Are you sure you want to lock this device?',
      parameters: {},
    };
  },
  mounted() {
    this.parent.$on('validateLockDevice', this.validateLockDevice);
  },
  async created() {
    this.onUpdateParameters(this.parameters);
  },
  methods: {
    validateLockDevice(actionId, companyId) {
      // this.$validator.validateAll().then((result) => {
      this.onResult(true, actionId, companyId, false);
      // });
    },
  },
};
</script>
